function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}
function convertToHttpLink(link)
{
    if(link  && link.indexOf("http://")<0 && link.indexOf("https://")<0)
    {
        return "http://"+link;
    }

    return link;
}


var SideMenus = function () {

    var boardsMenu = document.getElementById( 'cbp-spmenu-s1' ),
    notificationMenu = document.getElementById( 'cbp-spmenu-s2' ),
    showBoards = document.getElementById( 'boards' ),
    showNotifications = document.getElementById( 'notifications' ),
    body = document.body;


    // Added by Martin: Frank requested for menus
    // to be closed with an outside click.
    $( document ).click(function ( event ) {
        var targetId = event.target.id;
        if ( targetId !== 'boards' && targetId !== 'notifications' ) {
            if ( $(event.target).closest('#cbp-spmenu-s1, #cbp-spmenu-s2').length == 0 ) {
                if ( classie.has( boardsMenu, 'cbp-spmenu-open' ) ) {
                    showBoards.click();
                };
                if ( classie.has( notificationMenu, 'cbp-spmenu-open' ) ) {
                    showNotifications.click();
                };
            };
        };
    });


    showBoards.onclick = function() {
        classie.toggle( this, 'active' );
        classie.toggle( body, 'cbp-spmenu-push-toleft' );
        classie.toggle( boardsMenu, 'cbp-spmenu-open' );
        disableOther( 'boards' );
        return false;

    };

    showNotifications.onclick = function() {
        classie.toggle( this, 'active' );
        classie.toggle( body, 'cbp-spmenu-push-toleft' );
        classie.toggle( notificationMenu, 'cbp-spmenu-open' );
        disableOther( 'notifications' );
        return false;

    };

    function disableOther( button ) {
        if( button !== 'boards' ) {
            classie.toggle( showBoards, 'disabled' );
        }
        if( button !== 'notifications' ) {
            classie.toggle( showNotifications, 'disabled' );
        }
    }



    /* mobile version */
    var mobileMainMenu = document.getElementById( 'mobileMainMenu' ),
    mobileNotifications = notificationMenu,
    showMobileMainMenu = document.getElementById( 'mainMenu'),
    showMobileNotifications = document.getElementById( 'mobileNotificationButton' );
    //body = document.body;

    showMobileMainMenu.onclick = function() {
        classie.toggle( this, 'active' );
        classie.toggle( body, 'cbp-spmenu-push-toright' );
        classie.toggle( mobileMainMenu, 'cbp-spmenu-open' );
        disableOtherMobile( 'mainMenu' );
        return false;
    };

    showMobileNotifications.onclick = function() {
        classie.toggle( this, 'active' );
        classie.toggle( body, 'cbp-spmenu-push-toleft' );
        classie.toggle( mobileNotifications, 'cbp-spmenu-open' );
        disableOtherMobile( 'notifications' );
         return false;
    };

    function disableOtherMobile( button ) {
        if( button !== 'mainMenu' ) {
            classie.toggle( showMobileMainMenu, 'disabled' );
        }
        if( button !== 'notifications' ) {
            classie.toggle( showMobileNotifications, 'disabled' );
        }
    }

    this.closeMenu = function (menuName) {
        if (menuName == 'boards') {
            classie.toggle( showBoards, 'active' );
            classie.toggle( body, 'cbp-spmenu-push-toleft' );
            classie.toggle( boardsMenu, 'cbp-spmenu-open' );
            disableOther( menuName );
        } else if (menuName == 'notifications') {
            classie.toggle( showNotifications, 'active' );
            classie.toggle( body, 'cbp-spmenu-push-toleft' );
            classie.toggle( boardsMenu, 'cbp-spmenu-open' );
            disableOther( menuName );
        } else {
            classie.toggle( showMobileMainMenu, 'active' );
            classie.toggle( body, 'cbp-spmenu-push-toright' );
            classie.toggle( mobileMainMenu, 'cbp-spmenu-open' );
            disableOther( menuName );
        };
    };

};
function inView (viewModel) {
    var hidden = "hidden";

    // Standards:
    if (hidden in document)
        document.addEventListener("visibilitychange", onchange);
    else if ((hidden = "mozHidden") in document)
        document.addEventListener("mozvisibilitychange", onchange);
    else if ((hidden = "webkitHidden") in document)
        document.addEventListener("webkitvisibilitychange", onchange);
    else if ((hidden = "msHidden") in document)
        document.addEventListener("msvisibilitychange", onchange);
    // IE 9 and lower:
    else if ('onfocusin' in document)
        document.onfocusin = document.onfocusout = onchange;
    // All others:
    else
        window.onpageshow = window.onpagehide
            = window.onfocus = window.onblur = onchange;

    function onchange (evt) {

        var v = 'visible', h = 'hidden',
            evtMap = {
                focus:v, focusin:v, pageshow:v, blur:h, focusout:h, pagehide:h
            };

        evt = evt || window.event;
        if (evt.type in evtMap)
        {


             window.blurred = evtMap[evt.type]=="hidden";
             viewModel.windowBlurred(evtMap[evt.type]=="hidden");
        }
        else
        {
            viewModel.windowBlurred(this[hidden]);
            window.blurred = this[hidden];

        }

    }
}

if (!String.prototype.format) {
  String.prototype.format = function() {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
      ;
    });
  };
}

if (typeof String.prototype.endsWith !== 'function') {
    String.prototype.endsWith = function(suffix) {
        return this.indexOf(suffix, this.length - suffix.length) !== -1;
    };
}

var queryString = (function(a) {
    if (a == "") return {};
    var b = {};
    for (var i = 0; i < a.length; ++i)
    {
        var p=a[i].split('=', 2);
        if (p.length == 1)
            b[p[0]] = "";
        else
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    }
    return b;
})(window.location.search.substr(1).split('&'));
