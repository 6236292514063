function AuthViewModel(mvm, arguments) {
    var self = this;

    self.email = ko.observable();
    self.password = ko.observable();
    self.token = ko.observable();
    self.message = ko.observable();
    self.nextUrl = ko.observable(queryString["next"]);

    self.facebookLogin = function(){

        FB.login(function(response) {
            if (response.authResponse) {
                // FB has accepted login. Now log the user in to Cobbl.
                $.ajax({
                    url: "/facebook-login",
                    type: "GET",
                    success:function(result) {
                        if(result.success) {
                            if (result.next == 'signup') {
                                window.top.location.href = "/#!social-complete?name=" + result.name + "&email=" + result.email;
                            } else if(self.nextUrl() != null) {
                                window.top.location.href = self.nextUrl();
                            } else {
                                window.top.location.href = "/";
                            }
                        } else {
                            self.message({message:result.message, css:"alert-danger"});
                            self.submitting(undefined);
                        }
                    },
                    error: function(response) {
                        console.log(response);
                    }
                });

            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        },{scope: 'public_profile, email, user_photos'});

    };

}

function RegisterViewModel(mvm) {
    AuthViewModel.apply(this, arguments);
    var self = this;

    self.title = ko.observable("Sign up :)");
    self.name = ko.observable();
    self.connect = ko.observable(false);

    //these mock mainViewModel
    if(!mvm) {
        mvm = self;
    }
    self.loadingMessage = ko.observable();
    self.isLoggedIn = ko.observable();
    self.goToNextPage = function() {
        if(queryString["next"]) {
            window.top.location.href = queryString["next"];
        } else {
            window.top.location.href ="/";
        }
    };
    self.addMessage = function(message, severity) {
        self.message(message);
    };

    //operations
    self.register = function() {
        if(!self.email()) {
            self.message("An email address is required");
            return;
        }
        if(!self.password()) {
            self.message("Please choose a password");
            return;
        }
        if(!self.name()) {
            self.message("Please choose a username");
            return;
        }

        $.ajax(
            {
                url: "/create/",
                type: "POST",
                data: {
                        name: self.name(),
                        email: self.email(),
                        password: self.password(),
                        token: self.token,
                        connect: self.connect()
                       },
                success:function(result) {
                   //check if valid user, if valid user, take user to board screen.
                   //set current user in main view model so we know they're logged in.
                    if(result.success) {
                        //mvm.addMessage(result.message, "success");
                        mvm.isLoggedIn(true);
                        //location.hash = "!"+result.redirect;
                        //if(result.redirect)
                        //{
                        //    mvm.goToNextPage("!" + result.redirect);
                        //}
                        //else{
                        //    mvm.goToNextPage("!walls")
                        //}

                        mvm.goToNextPage();

                        self.name(undefined);
                        self.email(undefined);
                        self.password(undefined);
                    } else {
                        mvm.addMessage(result.message, "error", 60);

                    }

                }
            }
        );
    };

    self.postRenderLogic = function()   { };
}

function LoginViewModel(loginVisible) {
    AuthViewModel.apply(this, arguments);
    var self = this;

    self.submitting = ko.observable();
	self.loginVisible = ko.observable(loginVisible);

    self.submitText = ko.computed(function(){

        if(self.loginVisible())
        {
            if(self.submitting())
            {
                return "checking Login..."
            }
            return "Login"
        }
        else{
            if(self.submitting())
            {
                return "Resetting password..";
            }
            return "Reset password";
        }

    });

    self.showResetPassword = function(){
        self.loginVisible(false);
    };
    //operations

    self.login = function() {

        self.submitting(true);
        $.ajax({url: "/login",type:"POST",data:{ email:self.email(), password:self.password()},
            success:function(result) {
                if(result.success)
                {
                    if(self.nextUrl()!= null)
                    {
                        window.top.location.href = self.nextUrl();

                    }
					else{
						window.top.location.href = "/"
					}

                }
                else
                {
                    self.message({message:result.message, css:"alert-danger"});
                    self.password(undefined);
					self.submitting(undefined);
                }
            }
        });

    };

    self.resetPassword = function() {

        $.ajax({url: "/resetPasswordRequest",type:"POST",data:{ email:self.email()},
            success:function(result) {
                //send confirmation saying password reset has been sent.
                var style = "alert-success";
                if(!result.success)
                {
                    style = "alert-danger";
                }
                self.message({message:result.message, css:style});
            }
        });
    };

}

function ResetPasswordViewModel(parentViewModel) {
    var self = this;
    AuthViewModel.apply(this, arguments);
    self.submitText = ko.observable("Reset Password");
    self.confirmPassword = ko.observable();

    self.canContinue = ko.computed(function() {

        if(!self.password()) {
            return false;
        }

        if(self.password() && self.password().length < 5) {
            self.message({message:"Password too short", css:"alert-danger"});
            return false;
        }

        var passwordsDoNotMatch = self.confirmPassword()!=self.password();
        if(passwordsDoNotMatch) {
            self.message({message:"The passwords do not match", css:"alert-danger"});
            return false;
        } else {
             self.message(undefined);
            return true;
        }
    });
    //operations
    self.resetPassword = function() {
        self.submitText("resetting...");

        $.ajax({url: window.location,type:"POST",data:{ password:self.password()},
            success:function(result) {

                //send confirmation saying password reset has been sent.
                var style = "alert-success";
                if(!result.success)
                {
                    style = "alert-danger";
                }
                self.message({message:result.message, css:style});
                if(result.success){
                    setTimeout(function(){
                    window.location = '/';
                    }, 5000);
                }
            }
        });
    };

}