var homePageScript = function(){

			jQuery(document).scroll(function (e) {
				var y = $(this).scrollTop();
				if (y > 56) {
					$('.home-header').addClass('headerBg');
				} else {
					$('.home-header').removeClass('headerBg');
				}
				if (y > 170) {
					$('.logo').css("opacity", "0.8");
				} else {
					$('.logo').css("opacity", "0");
				}

			});

			$(".learnMoreDown").click(function() {
				$('html, body').animate({
					scrollTop: $("#slide6").offset().top
				}, 500);
			});

			// Collab animation
			var c = Snap('.introSlide2 svg'),
				firstCobbl = c.select('#light-orange-cobbl'),
				plus = c.select('#plus-3'),
				secondCobbl = c.select('#orange-cobbl'),
				firstUser = c.select('#violet-user'),
				firstVioletDash = c.select('#violet-dashed-1'),
				secondVioletDash = c.select('#violet-dashed-2'),
				thirdCobbl = c.select('#violet-cobbl'),
				it1 = c.select('#it-1'),
				secondUser = c.select('#lilac-users'),
				firstLilacDash = c.select('#lilac-dashed-1'),
				secondLilacDash = c.select('#lilac-dashed-2'),
				fourthCobbl = c.select('#lilac-cobbl'),
				it2 = c.select('#it-2'),
				thirdUser = c.select('#yellow-user'),
				firstYellowDash = c.select('#yellow-dashed-1'),
				secondYellowDash = c.select('#yellow-dashed-2'),
				fifthCobbl = c.select('#yellow-cobbl');

			firstCobbl.transform('s0r0,150,150');
			plus.transform('s0r0,150,150');
			secondCobbl.transform('s0r0,150,150');
			firstUser.transform('s0r0,150,150');
			thirdCobbl.transform('s0r0,150,150');
			it1.transform('s0r0,150,150');
			secondUser.transform('s0r0,150,150');
			fourthCobbl.transform('s0r0,150,150');
			it2.transform('s0r0,150,150');
			thirdUser.transform('s0r0,150,150');
			fifthCobbl.transform('s0r0,150,150');

			$('.introSlide2').viewportChecker({
				callbackFunction: function(elem, action){

					firstCobbl.animate({transform: 's1r0,150,150'},200,mina.easeinout, function(){
						plusAnim();
					});

					function plusAnim (callback) {
						plus.animate({transform: 's1r0,150,150'},500,mina.elastic, function(){
							secondCobblAnim();
						});
					}

					function secondCobblAnim (callback) {
						it2.animate({transform: 's1r0,150,150'}, 300, mina.easeinout);
						secondCobbl.animate({transform: 's1r0,150,150'},300,mina.easeinout, function(){
							firstUserAnim();
						});
					}

					function firstUserAnim (callback) {
						firstUser.animate({transform: 's1r0,150,150'},300,mina.easeinout, function(){
							firstVioletDashAnim();
						});
					}

					function firstVioletDashAnim(callback) {
						firstVioletDash.animate({x2: '161'}, 500,mina.easeinout, function(){
							secondVioletDashAnim();
						});
					}

					function secondVioletDashAnim(callback) {
						secondVioletDash.animate({y2: '124'}, 500,mina.easeinout, function(){
							thirdCobblAnim();
						});
					}

					function thirdCobblAnim (callback) {
						thirdCobbl.animate({transform: 's1r0,150,150'},500,mina.elastic, function(){
							secondUserAnim();
						});
					}

					function secondUserAnim (callback) {
						secondUser.animate({transform: 's1r0,150,150'}, 300, mina.easeinout, function(){
							firstTagAnim();
						});
					}

					function firstTagAnim (callback) {
						it1.animate({transform: 's1r0,150,150'}, 200, mina.easeinout, function(){
							firstLilacDashAnim();
						});
					}

					function firstLilacDashAnim(callback) {
						firstLilacDash.animate({y2: '331'}, 500,mina.easeinout, function(){
							secondLilacDashAnim();
						});
					}

					function secondLilacDashAnim(callback) {
						secondLilacDash.animate({x2: '128'}, 500,mina.easeinout, function(){
							fourthCobblAnim();
						});
					}

					function fourthCobblAnim (callback) {
						fourthCobbl.animate({transform: 's1r0,150,150'},200,mina.easeinout, function(){
							thirdUserAnim();
						});
					}

					function thirdUserAnim (callback) {
						thirdUser.animate({transform: 's1r0,150,150'}, 200, mina.easeinout, function(){
							firstYellowDashAnim();
						});
					}

					function firstYellowDashAnim(callback) {
						firstYellowDash.animate({x2: '334'}, 300,mina.easeinout, function(){
							secondYellowDashAnim();
						});
					}

					function secondYellowDashAnim(callback) {
						secondYellowDash.animate({y2: '350'}, 300,mina.easeinout, function(){
							fifthCobblAnim();
						});
					}

					function fifthCobblAnim (callback) {
						fifthCobbl.animate({transform: 's1r0,150,150'}, 500, mina.elastic);
					}

				}
			});

			// Ignite animation

			var i = Snap('.introSlide3 svg'),
				icVideoSm = i.select('#ic-video-sm'),
				icVideoBg = i.select('#ic-video-big'),
				icTextSm = i.select('#ic-text-sm'),
				icTextBg = i.select('#ic-text-big'),
				icImageSm = i.select('#ic-image-sm'),
				icImageBg = i.select('#ic-image-big'),
				icText2Sm = i.select('#ic-text-2-sm'),
				icText2Bg = i.select('#ic-text-2-big'),
				icLinkSm = i.select('#ic-link-sm'),
				icLinkBg = i.select('#ic-link-big'),
				icTagSm = i.select('#ic-tag-sm'),
				icTagBg = i.select('#ic-tag-big'),
				icRocket = i.select('#ic-rocket'),
				fire1 = i.select('#fire1'),
				fire2 = i.select('#fire2'),
				fire3 = i.select('#fire3'),
				fire4 = i.select('#fire4');

			icVideoSm.transform('s0r0,150,150');
			icVideoBg.transform('s0r0,150,150');
			icTextSm.transform('s0r0,150,150');
			icTextBg.transform('s0r0,150,150');
			icImageSm.transform('s0r0,150,150');
			icImageBg.transform('s0r0,150,150');
			icText2Sm.transform('s0r0,150,150');
			icText2Bg.transform('s0r0,150,150');
			icLinkSm.transform('s0r0,150,150');
			icLinkBg.transform('s0r0,150,150');
			icTagSm.transform('s0r0,150,150');
			icTagBg.transform('s0r0,150,150');

			var length1 = fire1.getTotalLength();
			var length2 = fire2.getTotalLength();
			var length3 = fire3.getTotalLength();
			var length4 = fire4.getTotalLength();

			fire1.attr({
				'stroke-dasharray': length1+' '+length1,
				'stroke-dashoffset': length1,
			});

			fire2.attr({
				'stroke-dasharray': length2+' '+length2,
				'stroke-dashoffset': length2,
			});

			fire3.attr({
				'stroke-dasharray': length3+' '+length3,
				'stroke-dashoffset': length3,
			});

			fire4.attr({
				'stroke-dasharray': length4+' '+length4,
				'stroke-dashoffset': length4,
			});

			$('.introSlide3').viewportChecker({
				callbackFunction: function(elem, action){

					icVideoSm.animate({transform: 's1r0,150,150'},300,mina.easeinout, function(){
						icVideoBgAnim();
					});

					function icVideoBgAnim (callback) {
						icVideoBg.animate({transform: 's1r0,150,150'},300,mina.easeinout, function(){
							icTextSmAnim();
						});
					}

					function icTextSmAnim (callback) {
						icTextSm.animate({transform: 's1r0,150,150'},300,mina.easeinout, function(){
							icTextBgAnim();
						});
					}

					function icTextBgAnim (callback) {
						icTextBg.animate({transform: 's1r0,150,150'},300,mina.easeinout, function(){
							icImageSmAnim();
						});
					}

					function icImageSmAnim (callback) {
						icImageSm.animate({transform: 's1r0,150,150'},300,mina.easeinout, function(){
							icImageBgAnim();
						});
					}

					function icImageBgAnim (callback) {
						icImageBg.animate({transform: 's1r0,150,150'},300,mina.easeinout, function(){
							icText2SmAnim();
						});
					}

					function icText2SmAnim (callback) {
						icText2Sm.animate({transform: 's1r0,150,150'},300,mina.easeinout, function(){
							icText2BgAnim();
						});
					}

					function icText2BgAnim (callback) {
						icText2Bg.animate({transform: 's1r0,150,150'},300,mina.easeinout, function(){
							icLinkSmAnim();
						});
					}

					function icLinkSmAnim (callback) {
						icLinkSm.animate({transform: 's1r0,150,150'},300,mina.easeinout, function(){
							icLinkBgAnim();
						});
					}

					function icLinkBgAnim (callback) {
						icLinkBg.animate({transform: 's1r0,150,150'},300,mina.easeinout, function(){
							icTagSmAnim();
						});
					}

					function icTagSmAnim (callback) {
						icTagSm.animate({transform: 's1r0,150,150'},300,mina.easeinout, function(){
							icTagBgAnim();
						});
					}

					function icTagBgAnim (callback) {
						icTagBg.animate({transform: 's1r0,150,150'},300,mina.easeinout, function(){
							igniteAnim();
						});
					}

					function igniteAnim (callback) {
						icRocket.animate({transform: 't185,-185'},500,mina.easeout );

						var strokeOffset1 = fire1.attr('stroke-dashoffset').replace('px', '');
						Snap.animate(strokeOffset1, '0', function( value ){
							fire1.attr({ 'stroke-dashoffset': value })
						}, 500, mina.easeout);

						var strokeOffset2 = fire2.attr('stroke-dashoffset').replace('px', '');
						Snap.animate(strokeOffset2, '0', function( value ){
							fire2.attr({ 'stroke-dashoffset': value })
						}, 500, mina.easeout);

						var strokeOffset3 = fire3.attr('stroke-dashoffset').replace('px', '');
						Snap.animate(strokeOffset3, '0', function( value ){
							fire3.attr({ 'stroke-dashoffset': value })
						}, 500, mina.easeout);

						var strokeOffset4 = fire4.attr('stroke-dashoffset').replace('px', '');
						Snap.animate(strokeOffset4, '0', function( value ){
							fire4.attr({ 'stroke-dashoffset': value })
						},500, mina.easeout);
					}
				}
			});


			// Share animation
			var s = Snap('.introSlide4 svg'),
				aquaCircle = s.select('#c-back circle'),
				cCenter = s.select('#c-center'),
				dashed1 = s.select('#dashed-line-1'),
				userCircle = s.select('#circle-outline-1'),
				icUser = s.select('#ic-user'),
				dashed2 = s.select('#dashed-line-2'),
				icUsers = s.select('#c-collab-2'),
				line3 = s.select('#line-2'),
				icLink = s.select('#c-tag'),
				line4 = s.select('#line-1'),
				icEmbed = s.select('#c-embed'),
				earthCircle = s.select('#circle-outline-2'),
				line5 = s.select('#dashed-line-3'),
				icEarth = s.select('#ic-earth');

			cCenter.transform('s0r0,150,150');
			circumf = Math.PI*(userCircle.attr('r')*2);
			userCircle.attr({
				'stroke-dasharray': circumf+' '+circumf,
				'stroke-dashoffset': circumf,
			});
			icUser.transform('s0r0,150,150');
			icUsers.transform('s0r0,150,150');
			icLink.transform('s0r0,150,150');
			icEmbed.transform('s0r0,150,150');
			icEarth.transform('s0r0,150,150');

			circumf2 = Math.PI*(earthCircle.attr('r')*2);
			earthCircle.attr({
				'stroke-dasharray': circumf2+' '+circumf2,
				'stroke-dashoffset': circumf2,
			});

			$('.introSlide4').viewportChecker({
				callbackFunction: function(elem, action){

					aquaCircle.animate({r: 212},1000,mina.bounce, function(){
						centerAnimation();
					});

					function centerAnimation (callback) {
						cCenter.animate({transform: 's1r0,150,150'},1000,mina.elastic, function(){
							firstDash();
						});
					}

					function firstDash(callback) {
						dashed1.animate({x2: '264.9', y2:"369"}, 500,mina.easeout, function(){
							userCircleAnim();
						});
					}

					function userCircleAnim(callback) {
						var strokeOffset = userCircle.attr('stroke-dashoffset').replace('px', '');
						Snap.animate(strokeOffset, '0', function( value ){
							userCircle.attr({ 'stroke-dashoffset': value })
						}, 500, mina.easein, function(){
							icUserAnim();
						});
					}

					function icUserAnim(callback) {
						icUser.animate({transform: 's1r0,150,150'},1000,mina.elastic, function(){
							secondDash();
						});
					}

					function secondDash(callback) {
						dashed2.animate({x2:'91', y2:'321.9'}, 500, mina.easeout, function(){
							icUsersAnim();
						});
					}

					function icUsersAnim(callback) {
						icUsers.animate({transform: 's1r0,150,150'},300, mina.easeinout, function(){
							thirdLine();
						});
					}

					function thirdLine(callback){
						line3.animate({x2:'419.7', y2:'358'}, 500, mina.easeout, function(){
							icLinkAnim();
						});
					}

					function icLinkAnim(callback){
						icLink.animate({transform: 's1r0,150,150'},500,mina.easeinout, function(){
							forthLine();
						});
					}

					function forthLine(callback) {
						line4.animate({x2:'131.5', y2:'61.5'}, 500, mina.easeout, function(){
							icCodeAnim();
						});
					}

					function icCodeAnim(callback) {
						icEmbed.animate({transform: 's1r0,150,150'},300, mina.easeinout, function(){
							fifthDash();
						});
					}

					function fifthDash(callback) {
						line5.animate({x2:'369.5', y2:'112.2'}, 500, mina.easeout, function(){
							earthCircleAnim();
						});
					}

					function earthCircleAnim(callback) {
						var strokeOffset = earthCircle.attr('stroke-dashoffset').replace('px', '');
						Snap.animate(strokeOffset, '0', function( value ){
							earthCircle.attr({ 'stroke-dashoffset': value })
						}, 1000, mina.easein, function(){
							icEarthAnim();
						});
					}

					function icEarthAnim(callback) {
						icEarth.animate({transform: 's1r0,150,150'},1000, mina.elastic);
					}


				}
			});

			// Type of users animation
			$('.introSlide5').viewportChecker();

			// Random backgroung for first slide
			var images = ['cobbl_bg.jpg', 'cobbl_bg-collecting_v3.jpg', 'cobbl_bg-event_v3.jpg', 'cobbl_bg-sell_v3.jpg'];
			$('.introSlide1').css({'background-image': 'url(img/' + images[Math.floor(Math.random() *      images.length)] + ')'});
};



function initPageWithViewModel(vm)
{
       ko.applyBindings(vm);
}


function clientSideRedirectRoutes()
{

	 Sammy(function() {



        this.get('#(.*)', function() {

			var hostEtc = window.location.href.replace(window.location.hash, '');
			hostEtc = hostEtc +"?isApp=true"+window.location.hash;
			location.replace(hostEtc);

		});
    }).run();
}





