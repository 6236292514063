ko.bindingHandlers.fbInit = {
    init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
        window.fbLoaded = false;
        window.fbAsyncInit = function () {
            FB.init({
                appId     : valueAccessor(),
                channelURL: "http://www.cobbl.com/_fb/channel.php",
                status    : true, // check login status
                cookie    : true, // enable cookies to allow the server to access the session
                oauth     : true, // enable OAuth 2.0
                xfbml     : true, // parse XFBML
                version   : 'v2.8'
            });

            FB.Event.subscribe('auth.logout', function (response) {

            });
            FB.Event.subscribe('auth.login', function (response) {
                if (typeof viewModel.goToFacebookLoggedIn !== 'undefined') {
                    //viewModel.goToFacebookLoggedIn();
                }
            });

            FB.getLoginStatus(function (response) {
                window.fbLoaded = true;
                if (typeof viewModel.FacebookUID !== 'undefined') {
                    viewModel.FacebookUID(null);
                    viewModel.FacebookAccessToken(null);
                    if (response.status === 'connected') {
                        // the user is logged in and has authenticated your
                        // app, and response.authResponse supplies
                        // the user's ID, a valid access token, a signed
                        // request, and the time the access token
                        // and signed request each expire
                        viewModel.FacebookUID(response.authResponse.userID);
                        viewModel.FacebookAccessToken(response.authResponse.accessToken);
                        viewModel.isFacebookLoggedIn(true);

                    } else if (response.status === 'not_authorized') {
                        // the user is logged in to Facebook,
                        // but has not authenticated your app
                        viewModel.isFacebookLoggedIn(false);
                    } else {
                        // the user isn't logged in to Facebook.
                    }
                }
            });
        };
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.async = true;
            js.src = "//connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }
};


function parseFB(element) {
    if (window.fbLoaded) {
        FB.XFBML.parse();
    } else {
        self.setTimeout(function () {
            parseFB(element)
        }, 50);
    }
}
